import React from "react";
import { IconButton } from "@mui/material";

import { ReactComponent as YoutubeImg } from "assets/images/icon_c_youtube.svg";

export function YoutubeIcon() {
  return (
    <IconButton href="https://www.youtube.com/c/TXOneNetworks" target="_blank">
      <YoutubeImg width={30} height={30} />
    </IconButton>
  );
}
export default YoutubeIcon;
